import metadata from './metadata'
import navigation from './navigation'
import footer from './footer'

/* eslint-disable */
export const fields = `
  _type == "siteSettingsMetadata" => { 
    _type,
    ${metadata.fields}
   },
  _type == "navigation" => { 
    _type,
    ${navigation.fields}
   },
  _type == "footer" => { 
    _type,
    ${footer.fields}
   }
`
/* eslint-enable */

export const fragment = (name = 'siteSettings') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
