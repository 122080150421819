import cmsSettings from '../cmsSettings'
import imageAsset from '../imageAsset'
import richTextBasic from '../richTextBasic'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  ${richTextBasic.fragment('description')},
  ${imageAsset.fragment('image')}
`

export const fragment = (name = 'titleAndImageHero') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
