import classNames from 'classnames'

const ChevronDown = ({ className }: { className?: string }) => {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.97266 0.999939L4.75992 5.21267L0.547187 0.999939"
        stroke="currentColor"
      />
    </svg>
  )
}

ChevronDown.displayName = 'ChevronDown'

export default ChevronDown
