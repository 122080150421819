import sections from './sections'
import { pageMetadataFields } from './_shared'

/* eslint-disable */
export const getFields = () => {
  return `
    ${pageMetadataFields},
    sections[] -> {
      ${sections.getFields()}
    }
  `
}
/* eslint-enable */

export const getSections = () => {
  return `
    ${sections.fragment('sections')}   
  `
}

const exported = {
  getFields,
  getSections,
}

export default exported
