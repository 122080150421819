'use client'

import classnames from 'classnames'
import styles from './NavigationMobileMenu.module.scss'
import { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import ChevronDown from '@/components/_svgs/ChevronDown'
import Link from '@/components/Link/Link'
import gsap from 'gsap'
import useStore from '@/store'

type NavigationMobileMenuProps = {
  navigationData: SanityNavigation | null
  className?: string
}

const NavigationMobileMenu = ({ className, navigationData }: NavigationMobileMenuProps) => {
  const $container = useRef<HTMLElement | null>(null)
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>(Array(navigationData?.links?.length).fill(null))
  const dropdownListRefs = useRef<(HTMLUListElement | null)[]>(Array(navigationData?.links?.length).fill(null))
  const firstDropdownIndex = useMemo(() => {
    return navigationData?.links
      ?.map((link, i) => {
        if (link.type === 'dropdown') return i
        return null
      })
      .filter(index => typeof index === 'number')[0]
  }, [navigationData])
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(firstDropdownIndex || -1)
  const $listItems = useRef<(HTMLLIElement | null)[]>([])
  const $closeButton = useRef<HTMLButtonElement | null>(null)
  const $blurBg = useRef<HTMLDivElement | null>(null)
  const $bg = useRef<HTMLDivElement | null>(null)
  const $inner = useRef<HTMLDivElement | null>(null)
  const setNavIsOpen = useStore(state => state.setNavIsOpen)
  const navIsOpen = useStore(state => state.navIsOpen)

  useEffect(() => {
    if (!$container.current || !$closeButton.current || !$blurBg.current || !$bg.current || !$inner.current) {
      return
    }

    gsap.killTweensOf([$container.current, $closeButton.current, $blurBg.current, $bg.current, $inner.current])

    const isActive = navIsOpen
    const duration = isActive ? 0.6 : 0.4
    const ease = 'Power3.easeOut'

    gsap.to($container.current, {
      x: isActive ? 0 : '105%',
      duration,
      ease,
      onComplete: () => {
        if (!isActive) {
          setActiveDropdownIndex(firstDropdownIndex || -1)
        }
      },
    })

    gsap.to($closeButton.current, {
      autoAlpha: isActive ? 1 : 0,
      delay: isActive ? duration * 0.5 : 0,
      duration,
      ease,
    })

    gsap.to($blurBg.current, {
      autoAlpha: isActive ? 1 : 0,
      x: isActive ? window.innerWidth * 0.8 * -1 : 0, // need this to avoid glow issue
      pointerEvents: isActive ? 'all' : 'none',
      duration,
      ease,
    })

    gsap.to($bg.current, {
      autoAlpha: isActive ? 0.3 : 0,
      pointerEvents: isActive ? 'all' : 'none',
      duration,
      ease,
    })

    gsap.to($inner.current, {
      autoAlpha: isActive ? 1 : 0,
      delay: isActive ? duration * 0.5 : 0,
      duration,
      ease,
    })
  }, [navIsOpen, setActiveDropdownIndex, firstDropdownIndex])

  useEffect(() => {
    if (!dropdownRefs.current?.length) return

    dropdownRefs.current.forEach((ref, i) => {
      const dropdownContainer = ref
      const inner = dropdownListRefs.current[i]
      const isActive = i === activeDropdownIndex
      if (!dropdownContainer || !inner) return
      const duration = isActive ? 0.5 : 0.3
      const ease = 'Power3.easeOut'
      const dropdownListItemsNodeList = inner.querySelectorAll('li')
      const height = inner.offsetHeight
      const dropdownListItems = Array.from(dropdownListItemsNodeList)
      gsap.killTweensOf([dropdownContainer, dropdownListItems])
      gsap.to(dropdownContainer, {
        height: isActive ? height : 0,
        duration,
        ease,
      })
      gsap.to(dropdownListItems, {
        delay: isActive ? duration * 0.3 : 0,
        stagger: isActive ? 0.07 : 0,
        opacity: isActive ? 1 : 0,
        pointerEvents: isActive ? 'all' : 'none',
        duration: isActive ? duration : duration * 0.7,
        ease,
      })
    })
  }, [activeDropdownIndex])

  if (!navigationData) return null

  return (
    <>
      <div
        className={styles.bgBlur}
        ref={$blurBg}
        onClick={() => {
          setNavIsOpen(false)
        }}
      />
      <div
        className={styles.bg}
        ref={$bg}
      />
      <div
        ref={ref => {
          $container.current = ref
        }}
        className={classnames(styles.NavigationMobileMenu, className)}
        aria-hidden={!navIsOpen}
      >
        <button
          ref={$closeButton}
          className={styles.closeButton}
          aria-label="close mobile menu"
          onClick={() => {
            setNavIsOpen(false)
          }}
        >
          <svg
            className={styles.x}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="9.80765"
              y1="9.80758"
              x2="28.1924"
              y2="28.1924"
              stroke="currentColor"
            />
            <line
              x1="10.1611"
              y1="28.546"
              x2="28.5459"
              y2="10.1612"
              stroke="currentColor"
            />
          </svg>
        </button>
        <div
          className={styles.inner}
          ref={$inner}
        >
          <ul className={styles.list}>
            {navigationData?.links.map((level1Content, i) => {
              const hasDropdown = level1Content?.dropdownLinks?.length && level1Content?.type === 'dropdown'
              const isActiveDropdown = activeDropdownIndex === i

              let content = null
              if (level1Content?.type === 'dropdown' && level1Content?.title) {
                content = (
                  <button
                    onClick={() => {
                      setActiveDropdownIndex(prev => {
                        if (i === prev) return -1
                        return i
                      })
                    }}
                    className={classNames(styles.level1Title, { [styles.isActive]: activeDropdownIndex === i })}
                  >
                    <span className={styles.level1Title__text}>{level1Content?.title}</span>
                    <span className={styles.level1Title__icon}>
                      <ChevronDown />
                    </span>
                  </button>
                )
              } else if (level1Content?.type === 'link' && level1Content?.link) {
                content = (
                  <Link
                    className={styles.level1Link}
                    link={level1Content?.link}
                    onClick={() => {
                      setActiveDropdownIndex(-1)
                      setNavIsOpen(false)
                    }}
                    activeClass={styles.activeLink}
                  />
                )
              }

              let dropdownLinkContent = null
              if (hasDropdown) {
                dropdownLinkContent = (
                  <div
                    className={styles.dropdownContent}
                    ref={ref => {
                      dropdownRefs.current[i] = ref
                    }}
                  >
                    <ul
                      className={styles.dropdownContent__list}
                      ref={ref => {
                        dropdownListRefs.current[i] = ref
                      }}
                    >
                      {level1Content?.dropdownLinks?.map((link, i) => (
                        <li
                          key={i}
                          className={styles.dropdownContent__listItem}
                        >
                          <Link
                            link={link}
                            className={styles.dropdownContent__link}
                            onClick={() => {
                              setActiveDropdownIndex(-1)
                              setNavIsOpen(false)
                            }}
                            activeClass={styles.activeLink}
                            tabIndexHidden={!isActiveDropdown}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              }

              return (
                <li
                  key={i}
                  className={styles.listItem}
                  ref={ref => {
                    $listItems.current[i] = ref
                  }}
                >
                  {content}
                  {dropdownLinkContent}
                </li>
              )
            })}
            <li
              className={styles.listItem}
              ref={ref => {
                $listItems.current[navigationData?.links.length] = ref
              }}
            >
              <Link
                className={styles.level1Link}
                link={navigationData?.loginLink}
                activeClass={styles.activeLink}
                onClick={() => {
                  setNavIsOpen(false)
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

NavigationMobileMenu.displayName = 'NavigationMobileMenu'

export default NavigationMobileMenu
