import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  lottieIconAnimation,
  eyebrow,
  title[] {
    ...,
    markDefs[]{
      ...
    }
  }
`

export const fragment = (name = 'richTextWithIcon') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
