import { imageAsset } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  "sectionBackground": "light",
  title,
  ${imageAsset.fragment('image')}
`

export const fragment = (name = 'homepageHero') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
