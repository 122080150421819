import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { Coordinate } from '@/types/GeneralTypes'

type StoreValues = {
  // Global Settings
  canInteract: boolean
  lastElementInFocus: HTMLElement | null
  pageIsTransitioning: boolean
  fontsLoaded: boolean

  // Navigation
  navIsOpen: boolean

  // Interaction
  scrollY: number
  mouseCoordinates: Coordinate
}

type StoreSetters = {
  // Global Settings
  setCanInteract: (value: StoreValues['canInteract']) => void
  setLastElementInFocus: (value: StoreValues['lastElementInFocus']) => void
  setPageIsTransitioning: (value: StoreValues['pageIsTransitioning']) => void
  setFontsLoaded: (value: StoreValues['fontsLoaded']) => void

  // Navigtion
  setNavIsOpen: (value: StoreValues['navIsOpen']) => void

  // Interaction
  setScrollY: (value: number) => void
  setMouseCoordinates: (value: Coordinate) => void
}

type CombinedStoreValuesAndSetters = StoreValues & StoreSetters

export const useStore = create(
  subscribeWithSelector<CombinedStoreValuesAndSetters>(set => ({
    // Global Settings
    canInteract: true,
    setCanInteract: canInteract => set({ canInteract }),
    lastElementInFocus: null,
    setLastElementInFocus: lastElementInFocus => set({ lastElementInFocus }),
    pageIsTransitioning: false,
    setPageIsTransitioning: pageIsTransitioning => set({ pageIsTransitioning }),
    fontsLoaded: false,
    setFontsLoaded: fontsLoaded => set({ fontsLoaded }),

    // Navigation
    navIsOpen: false,
    setNavIsOpen: navIsOpen => set({ navIsOpen }),

    // Interaction
    scrollY: 0,
    setScrollY: (scrollY: number) => set({ scrollY }),
    mouseCoordinates: { x: 0, y: 0 },
    setMouseCoordinates: mouseCoordinates => set({ mouseCoordinates }),
  })),
)

export default useStore
