import link from './link'
import { imageAsset } from '@/data/sanity/fragments'
import person from './person'

export const fields = `
  blogData {
    publishedDate,
    "blogCategory": blogCategory->title,
    ${person.fragment('author')},
    subtitle[] {
      _key,
      _type,
      children[] {...},
      style,
      level,
      markDefs[]{...},
    },
    ${imageAsset.fragment('image')},
    externalLinkOverride,
    styledTitle[] {
      _key,
      _type,
      children[] {...},
      style,
      level,
      markDefs[]{...},
    }
  },
  content[] {
    _key,
    _type,
    children[] {
      ...,
      _type == "link" => {
        ${link.fields}
      },
    },
    style,
    listItem,
    level,
    markDefs[]{
      ...,
      _type == "link" => {
        ${link.fields}
      },
    },
    _type == "imageBlock" => {
      "imageBlock": {
        imageStyle,
        ${imageAsset.fragment('image')},
        text,
        useSerifCaption
      }
    },
    _type == "blockquote" => {
      "blockquote": {
        text[] {
          _key,
          _type,
          children[] {...},
          style,
          level,
          markDefs[]{...},
        }
      }
    },
    _type == "vimeoEmbed" => {
      "vimeoEmbed": {
        vimeoId,
        mutedAndLooping,
        fullWidth,
        "aspectRatio": coalesce(aspectRatio, "16/9")
      }
    },
    _type == "link" => {
      ${link.fields}
    },
    _type == "pdfContactForm" => {
      "pdfContactForm": {
        smallText,
        pdfName
      }
    },
    _type == "blogPostFootnote" => {
      "blogPostFootnote": {
        text[]{
          _key,
          _type,
          children[] {...},
          style,
          level,
          markDefs[]{
            ...,
            _type == "link" => {
              ${link.fields}
            },
          },
        }
      }
    },
  }
`

export const fragment = (name = 'blogPost') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
