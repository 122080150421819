import metadata from './metadata'
import blogPost from './blogPost'
import pageData from './pageData'
import { videoAsset } from '@/data/sanity/fragments'

/* eslint-disable */
export const pageMetadataFields = `
  _type,
  _createdAt,
  _updatedAt,
  title,
  slug,
  isEnabled,
  metadata {
    ${metadata.fields}
  },
  _type == "page" => {
    ${pageData.fields}
  },
  _type == "blogPost" => {
    ${blogPost.fields}
  },
  _type == "pdfPost" => {
    pdfData {
      pdfType,
      pdfUrl,
      ${videoAsset.fragment('file')},
      fileName
    }
  },
  "globalMetaData": {
    ...(*[_type == "siteSettingsMetadata"] {
      _type,
      ${metadata.fields}
    }[0])
  }
`

export const pageSitemapFields = `
  _type,
  _updatedAt,
  slug,
  isEnabled,
  metadata {
    ${metadata.fields}
  }
`

/* eslint-enable */
