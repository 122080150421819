// Doc Types
export const DOC_TYPES = {
  PAGE: 'page',
  BLOG_POST: 'blogPost',
  PDF_POST: 'pdfPost',
}

export const TRANSITION_DURATION = 1

// Slugs / Paths
export const HOME_SLUG = 'home'
export const FOUR_OH_FOUR_SLUG = '404'
export const BLOG_SLUG = 'project-highlights'
export const PDF_SLUG = 'pdf'

export const LOTTIE_BASE = '/lottie/'
export const LOTTIE_SYMBOL_PATHS = {
  1: `${LOTTIE_BASE}symbol-cultural-heritage.json`,
  2: `${LOTTIE_BASE}symbol-nc3.json`,
  3: `${LOTTIE_BASE}symbol-orphaned-artwork.json`,
}
