import { DOC_TYPES } from '@/data'
import { usePathname } from 'next/navigation'
import { useParams } from 'next/navigation'

const useCurrentPage = () => {
  const params = useParams()
  const paramsAsArray = Object.keys(params)
  const currentPath = usePathname()

  let currentDocType = DOC_TYPES.PAGE
  let currentSlug = params?.slug || ''

  if (currentPath.split('/').filter(item => item).length === 2 && params?.slug === undefined) {
    currentSlug = currentPath.split('/').filter(item => item)[1]
  } else if (paramsAsArray.includes('blogPostSlug')) {
    currentDocType = DOC_TYPES.BLOG_POST
    currentSlug = params.blogPostSlug
  }

  return { currentPath, currentDocType, currentSlug }
}

useCurrentPage.displayName = 'useCurrentPage'

export default useCurrentPage
