import link from './link'

export const fields = `
  links[] {
    type,
    title,
    'link': link[0]{${link.fields}},
    dropdownLinks[]{${link.fields}}
  },
  loginLink{${link.fields}}
`

export const fragment = (name = 'navigation') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
