import cmsSettings from '../cmsSettings'
import { imageAsset, richTextBasic } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  eyebrow,
  title,
  content[]{
    ${richTextBasic.fields},
    _type == "imageAsset" => {
      ${imageAsset.fields}
    },
  },
  ${imageAsset.fragment('bottomImage')}
`

export const fragment = (name = 'titleAndRichTextScroller') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
