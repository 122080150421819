import cmsSettings from '../cmsSettings'
import richTextBasic from '../richTextBasic'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  items[]{
    isHidden,
    title,
    ${richTextBasic.fragment('description')}
  }
`

export const fragment = (name = 'accordion') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
