import { BLOG_SLUG, DOC_TYPES, HOME_SLUG, PDF_SLUG } from '@/data'
import { getPage } from '@/data/sanity'
import { sendGTMEvent } from '@next/third-parties/google'

export const wait = (ms = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const lerp = (currentValue: number, targetValue: number, ease = 0.1) => {
  return currentValue + (targetValue - currentValue) * ease
}

export const setBodyCursor = (cursor: 'pointer' | 'drag' | 'dragging' | null) => {
  document.body.dataset.cursor = cursor || 'normal'
}

export const waitForWebfonts = (fonts: string[], callback: () => void) => {
  let loadedFonts = 0

  function loadFont(font: string) {
    let node: HTMLSpanElement | null = document.createElement('span')
    // Characters that vary significantly among different fonts
    node.innerHTML = 'giItT1WQy@!-/#'
    // Visible - so we can measure it - but not on the screen
    node.style.position = 'absolute'
    node.style.left = '-10000px'
    node.style.top = '-10000px'
    // Large font size makes even subtle changes obvious
    node.style.fontSize = '300px'
    // Reset any font properties
    node.style.fontFamily = 'sans-serif'
    node.style.fontVariant = 'normal'
    node.style.fontStyle = 'normal'
    node.style.fontWeight = 'normal'
    node.style.letterSpacing = '0'
    document.body.appendChild(node)

    // Remember width with no applied web font
    const width = node.offsetWidth

    node.style.fontFamily = font

    let interval: ReturnType<typeof setInterval> | null = null

    function checkFont() {
      // Compare current width with original width
      if (node && node.offsetWidth != width) {
        ++loadedFonts
        if (node.parentNode) {
          node.parentNode.removeChild(node)
        }

        node = null
      }

      // If all fonts have been loaded
      if (loadedFonts >= fonts.length) {
        if (interval) {
          clearInterval(interval)
        }
        if (loadedFonts == fonts.length) {
          callback()
          return true
        }
      }
    }

    if (!checkFont()) {
      interval = setInterval(checkFont, 50)
    }
  }

  for (let i = 0, l = fonts.length; i < l; ++i) {
    loadFont(fonts[i])
  }
}

export const getCssVar = (variable: string) => {
  return window.getComputedStyle(document.body).getPropertyValue(`--${variable}`)
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return { unit: 'Bytes', size: 0 }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return {
    size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: sizes[i],
  }
}

export const bytesToMb = (bytes: number) => {
  const { size, unit } = formatBytes(bytes)

  let value: number = size

  if (unit === 'KB') {
    value = value / 1024
  }

  return value
}

export const buildIdFromText = (input: string) => {
  return input
    .trim()
    .replace(/[^\w-\s+]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const getImageUrl = (
  image: SanityImage,
  {
    width = null,
    height = null,
    fit = null,
    quality = 80,
    invert = false,
    dpr = 1,
    fm = 'webp',
    sat,
    rect = null,
  }: SanityImageOptions,
) => {
  if (!image?.asset?.url) {
    console.warn('No image.asset.url in image object supplied. ', image)
    return null
  }

  let url = image.asset.url

  const params = []
  if (width) params.push(`w=${width}`)
  if (height) params.push(`h=${height}`)
  if (quality) params.push(`q=${quality}`)
  if (invert) params.push(`invert=${invert}`)
  if (fm) params.push(`fm=${fm}`)
  if (sat) params.push(`sat=${sat}`)
  if (rect) params.push(`rect=${rect}`)
  if (fit) params.push(`fit=${fit}`)
  if (dpr) params.push(`dpr=${dpr}`)

  url = `${url}?${params.join('&')}`

  return url as string
}

export const mergeSiteSettings = (pageData: SanityPage, settingsMetaData: SanityMetadata | null) => {
  if (!settingsMetaData) return pageData

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ignoreNulls: any = {}
  if (pageData?.metadata) {
    Object.keys(pageData?.metadata).forEach(key => {
      if (pageData?.metadata[key as keyof SanityMetadata] !== null) {
        if (key === 'image' && !pageData?.metadata?.image?.asset?.url) return
        ignoreNulls[key as keyof SanityMetadata] = pageData?.metadata[key as keyof SanityMetadata]
      }
    })
  }

  const merged: SanityMetadata = {
    image: settingsMetaData.image,
    favicon: settingsMetaData.favicon,
    metaBackgroundColorHex: settingsMetaData.metaBackgroundColorHex,
    themeColorHex: settingsMetaData.themeColorHex,
    ...ignoreNulls,
    defaultSiteKeywords: settingsMetaData.keywords,
    defaultSiteTitle: settingsMetaData.title,
    defaultSiteDescription: settingsMetaData.description,
  }

  pageData.metadata = merged

  return pageData
}

export const formatSiteSettingsResponse = (response: SanitySiteSettingsResponse) => {
  if (!response) return {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatted: any = {}

  response?.forEach(setting => {
    if (!formatted[setting._type]) {
      formatted[setting._type] = setting
    }
  })

  return formatted
}

export const getPagePathBySlug = (slug: string) => {
  if (slug === HOME_SLUG) return '/'
  return `/${slug}`
}

export const getBlogPostPathBySlug = (slug: string) => {
  return `/${BLOG_SLUG}/${slug}`
}

export const getPdfPostPathBySlug = (slug: string) => {
  return `/${PDF_SLUG}/${slug}`
}

export const getUrlFromPageData = (pageType: string, slug: string): string => {
  let url = ''

  switch (pageType) {
    case DOC_TYPES.PAGE:
      url = getPagePathBySlug(slug)
      break
    case DOC_TYPES.BLOG_POST:
      url = getBlogPostPathBySlug(slug)
      break
    case DOC_TYPES.PDF_POST:
      url = getPdfPostPathBySlug(slug)
      break
    default:
      break
  }

  return url
}

export const generateOrgStructuredDataSchema = ({
  companyTitle,
  companyDescription,
  image,
  addressInfo,
  telephone,
  foundingDate,
  numberOfEmployees,
  email,
  otherLinks,
}: SanityOrgStructuredData) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: companyTitle,
    description: companyDescription,
    url: process.env.NEXT_PUBLIC_SITE_URL,
    logo: image.asset.url,
    foundingDate: foundingDate,
    address: {
      '@type': 'PostalAddress',
      streetAddress: addressInfo.streetAddress,
      addressLocality: addressInfo.locality,
      addressRegion: addressInfo.region,
      postalCode: addressInfo.postalCode,
      addressCountry: addressInfo.country,
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: `[+${telephone}]`,
      email: email,
    },
    numberOfEmployees,
    sameAs: otherLinks,
  }

  return JSON.stringify(data)
}

export const getDeviceInfo = () => {
  const isBrowser = typeof window !== 'undefined'

  /* eslint-disable */
  const detect = {
    device: {},
    browser: {},
    os: {},
    bots: {},
    isTouchDevice: isBrowser && ('ontouchstart' in window || navigator.maxTouchPoints > 0),
  } as {
    device: any
    browser: any
    os: any
    bots: any
    isTouchDevice: boolean
  }

  if (isBrowser) {
    detect.device = require('@jam3/detect').device
    detect.browser = require('@jam3/detect').browser
    detect.os = require('@jam3/detect').os
    detect.bots = require('@jam3/detect').bots
  }

  /* eslint-disable */

  return detect
}

export const deviceInfo = getDeviceInfo()

export const getMenuPagePreviewKey = (type: string, slug: string) => `${type}_${slug}`

export const simpleImagesPreload = ({
  urls,
  onComplete,
  onProgress,
}: {
  urls: string[]
  onComplete?: () => void
  onProgress?: (percent: number, url: string) => void
}) => {
  let loadedCounter = 0
  const toBeLoadedNumber = urls.length

  urls.forEach(function (url) {
    preloadImage(url, function () {
      loadedCounter++
      if (onProgress) {
        onProgress(loadedCounter / toBeLoadedNumber, url)
      }
      if (loadedCounter === toBeLoadedNumber) {
        if (onComplete) onComplete()
      }
    })
  })

  function preloadImage(url: string, anImageLoadedCallback: () => void) {
    const img = new Image()
    img.onload = anImageLoadedCallback
    img.src = url
  }
}

export const formatMetadata = (metadata: SanityMetadata | null | undefined, pageData?: SanityPage | null) => {
  if (!metadata || !metadata.image || !metadata.favicon) return {}

  // Title
  let pageTitle = metadata.title || pageData?.title

  let title = `${pageTitle} | ${metadata.defaultSiteTitle}`
  if (pageTitle === metadata.defaultSiteTitle) {
    title = metadata.defaultSiteTitle || ''
  }

  // Description
  let description = metadata.defaultSiteDescription
  if (metadata.description) {
    description = metadata.description
  }

  // Keywords
  let keywords = metadata.defaultSiteKeywords
  if (metadata.keywords) {
    keywords = metadata.keywords
  }

  const data: any = {
    title,
    robots: {
      index: metadata.allowCrawlers,
      follow: metadata.allowCrawlers,
      nocache: !metadata.allowCrawlers,
      googleBot: {
        index: metadata.allowCrawlers,
        follow: metadata.allowCrawlers,
        noimageindex: !metadata.allowCrawlers,
      },
    },
    metadataBase: new URL(process.env.NEXT_PUBLIC_SITE_URL || ''),
    description,
    keywords: keywords?.split(','),
    openGraph: {
      title,
      description,
      siteName: metadata.defaultSiteTitle,
      images: [
        {
          url: getImageUrl(metadata.image, { width: 1200, height: 630, fit: 'crop' }) || '',
          width: 1200,
          height: 630,
          alt: metadata.title,
        },
      ],
      type: 'website',
    },
    twitter: {
      card: 'summary_large_image',
      title,
      description,
      images: [
        {
          url: getImageUrl(metadata.image, { width: 1024, height: 512, fit: 'crop' }) || '',
          width: 1024,
          height: 512,
          alt: metadata.title,
        },
      ],
    },
  }

  return data
}

export const getFormattedMetadata = async (slug: string, docType: string, isDraftMode: boolean) => {
  const data = await getPage(slug, docType, isDraftMode)
  const metadata = data?.metadata
  return formatMetadata(metadata, data as SanityPage)
}

export const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
export const ID_LENGTH = 10
export const createRandomString = (length = ID_LENGTH) => {
  const chars = CHARS
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export const getSections = (data: SanityPage) => {
  if (data._type === 'page') {
    return data.sections
  }

  if (data._type === 'blogPost') {
    const newSections: any[] = []

    const blogPostContent = {
      section: [
        {
          _type: 'blogContent',
          _id: 'blogContent',
          cmsSettings: {
            isHidden: false,
            cmsTitle: '',
          },
          sectionBackground: 'light' as SectionBackground,
          title: data?.title || '',
          content: data?.content,
          publishedDate: data?.blogData?.publishedDate || data?._createdAt,
          category: data?.blogData?.blogCategory,
          subtitle: data?.blogData?.subtitle,
          image: data?.blogData?.image,
          author: data?.blogData?.author,
          data,
        },
      ],
    }

    newSections.push(blogPostContent)

    if (!newSections.length) return data.sections

    const sections: SectionsSchema = [...newSections]

    if (data?.sections?.length) {
      data?.sections.forEach(section => {
        sections.push(section)
      })
    }

    const filteredSections = sections.filter(section => Object.keys(section.section[0]).length)

    return filteredSections
  }

  return data.sections
}

export const setCookie = ({ name, value, maxAge }: { name: string; value: string; maxAge: number }) => {
  document.cookie = `${name}=${value}; path=/; max-age=${maxAge};`
}

export const getCookie = (name: string) => {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
  return null
}

export const deleteCookie = (name: string) => {
  setCookie({ name, value: '', maxAge: 0 })
}

export const replaceTextStringWithVars = (textString: string, variables: any) => {
  Object.keys(variables).forEach(variable => {
    textString = textString.replaceAll(`#{${variable}}`, variables[variable])
  })
  return textString
}

export const formatMilitaryTime = (hourString: string) => {
  const hoursSplit = hourString?.split(':')
  if (hoursSplit?.length === 1) return hourString
  const hour = parseInt(hoursSplit[0]) ? parseInt(hoursSplit[0]) : 0
  const minutes = hoursSplit[1] ? parseInt(hoursSplit[1]) : 0

  const amPmHours = hour > 12 ? hour - 12 : hour
  const amPm = hour >= 12 ? 'PM' : 'AM'
  const amPmMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

  return `${amPmHours}:${amPmMinutes} ${amPm}`
}

export const getFormattedDateString = (dateString: string) => {
  const dateObject = new Date(dateString)
  const date = new Date(dateObject)
  date.setDate(date.getDate() + 1)
  date.setHours(0, 0, 0, 0)
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
  const year = date.getFullYear()
  const month = date.toLocaleString('default', { month: 'short' })

  return `${month} ${day} ${year}`
}

export const sendEvent = (event: string, value: string) => {
  if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_GTM_ID) {
    sendGTMEvent({ event, value })
  }
}

export const shuffleArray = <T = unknown>(array: T[]) => {
  const shuffled = [...array]
  for (var i = shuffled.length - 1; i >= 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = shuffled[i]
    shuffled[i] = shuffled[j]
    shuffled[j] = temp
  }

  return shuffled
}
