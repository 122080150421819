import imageAsset from './imageAsset'

export const fields = `
  fullName,
  designation,
  bio,
  ${imageAsset.fragment('image')}
`

export const fragment = (name = 'person') => `${name}->{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
