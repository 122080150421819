import cmsSettings from '../cmsSettings'
import { pageMetadataFields } from '../_shared'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  description,
  enableFilters,
  "items": *[_type == "blogPost"]{ ${pageMetadataFields} }
`

export const fragment = (name = 'paginatedBlogPosts') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
