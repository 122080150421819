'use client'

import { useContext, useEffect, useRef, useState } from 'react'
import styles from './Navigation.module.scss'
import { GlobalSettingsContext } from '@/context/GlobalSettings'
import Link from '@/components/Link/Link'
import { DOC_TYPES, HOME_SLUG, PDF_SLUG } from '@/data'
import lottie, { AnimationItem } from 'lottie-web'
import ChevronDown from '@/components/_svgs/ChevronDown'
import gsap from 'gsap'
import classNames from 'classnames'
import { ScrollContext } from '@/context/Scroll'
import NavigationMobileMenu from '@/components/Navigation/NavigationMobileMenu/NavigationMobileMenu'
import useBreakpoint from '@/hooks/use-breakpoint'
import useStore from '@/store'
import useCurrentPage from '@/hooks/use-current-page'
import { getUrlFromPageData } from '@/utils'
import FocusLock from 'react-focus-lock'
import { usePathname } from 'next/navigation'

export const HEADER_ID = 'header'

const Navigation = () => {
  const containerRef = useRef<HTMLElement>(null)
  const logoContainerRef = useRef<HTMLDivElement>(null)
  const mobileButtonRef = useRef<HTMLButtonElement | null>(null)
  const lottieAnimationRef = useRef<AnimationItem>()
  const { navigationData } = useContext(GlobalSettingsContext)
  const dropdownRefs = useRef<{ bg: HTMLDivElement | null; list: HTMLDivElement | null }[]>(
    Array(navigationData?.links?.length).fill(null),
  )
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(-1)
  const { onScrollCallback } = useContext(ScrollContext)
  const prevScroll = useRef(0)
  const [navState, setNavState] = useState<'HIDDEN' | 'VISIBLE'>('VISIBLE')
  const { isMobile } = useBreakpoint()
  const setNavIsOpen = useStore(state => state.setNavIsOpen)
  const { currentPath } = useCurrentPage()
  const pathname = usePathname()
  const isPdfPath = pathname.includes(`/${PDF_SLUG}/`)

  useEffect(() => {
    if (!containerRef.current) return

    const elements = [containerRef.current]
    if (mobileButtonRef.current) elements.push(mobileButtonRef.current)
    gsap.killTweensOf(elements)

    const duration = 0.3
    const ease = 'Power3.easeOut'

    if (navState === 'VISIBLE') {
      gsap.to(containerRef.current, {
        y: 0,
        duration,
        ease,
      })
      if (mobileButtonRef.current) {
        gsap.to(mobileButtonRef.current, {
          y: 0,
          duration,
          ease,
        })
      }
    } else if (navState === 'HIDDEN') {
      setActiveDropdownIndex(-1)
      gsap.killTweensOf(containerRef.current)
      gsap.to(containerRef.current, {
        y: '-105%',
        duration,
        ease,
      })
      if (mobileButtonRef.current) {
        gsap.to(mobileButtonRef.current, {
          y: '-105%',
          duration,
          ease,
        })
      }
    }
  }, [navState])

  useEffect(() => {
    const id = 'nav_on_scroll'

    onScrollCallback({
      key: `scroll_${id}`,
      remove: true,
    })

    onScrollCallback({
      key: `scroll_${id}`,
      callback: e => {
        if (e.targetScroll < 0) return
        const currentScroll = e.targetScroll
        if (currentScroll > prevScroll.current) {
          setNavState('HIDDEN')
        } else if (currentScroll < prevScroll.current) {
          setNavState('VISIBLE')
        }
        prevScroll.current = currentScroll
      },
    })
  }, [onScrollCallback])

  useEffect(() => {
    if (!logoContainerRef.current && !lottieAnimationRef.current) return

    if (lottieAnimationRef.current) {
      lottieAnimationRef.current.destroy()
    }

    lottieAnimationRef.current = lottie.loadAnimation({
      container: logoContainerRef.current as HTMLDivElement,
      renderer: 'svg',
      loop: false,
      path: '/lottie/wordmark-purple.json',
      autoplay: false,
    })
    lottieAnimationRef.current.play()

    return () => {
      if (lottieAnimationRef.current) {
        lottieAnimationRef.current.destroy()
      }
    }
  }, [isPdfPath])

  useEffect(() => {
    if (!dropdownRefs.current?.length) return

    dropdownRefs.current.forEach((ref, i) => {
      const bg = ref?.bg
      const list = ref?.list
      const isActive = i === activeDropdownIndex
      if (!bg || !list) return

      const duration = isActive ? 0.6 : 0.4
      const ease = 'Power3.easeOut'
      gsap.killTweensOf([bg, list])
      gsap.set(bg, {
        height: list.offsetHeight,
      })
      gsap.to(bg, {
        // height: isActive ? list.offsetHeight : 0,
        scaleY: isActive ? 1 : 0,
        duration,
        ease,
      })
      gsap.to(list, {
        opacity: isActive ? 1 : 0,
        pointerEvents: isActive ? 'all' : 'none',
        delay: isActive ? duration * 0.3 : 0,
        duration,
        ease,
      })
    })
  }, [activeDropdownIndex])

  if (isPdfPath) return null
  if (!navigationData || !navigationData?.links?.length || !navigationData?.loginLink) return null

  return (
    <>
      <header
        className={styles.Navigation}
        ref={containerRef}
        id={HEADER_ID}
      >
        <div className={styles.inner}>
          <Link
            className={styles.logoLink}
            link={{
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: HOME_SLUG,
              },
            }}
            ariaLabel="Go home"
          >
            <span
              ref={logoContainerRef}
              className={styles.logoContainer}
            />
          </Link>
          <nav className={styles.rightContent}>
            <ul className={styles.rightContent__list}>
              {navigationData?.links.map((level1Content, i) => {
                const hasDropdown = level1Content?.dropdownLinks?.length && level1Content?.type === 'dropdown'
                const isActiveDropdown = activeDropdownIndex === i
                const linkValue = level1Content.link?.link as SanityLinkInternal
                let linkIsActive = currentPath === getUrlFromPageData(linkValue?._type, linkValue?.slug)

                let hasActiveDropdownLink = false
                if (level1Content?.type === 'dropdown') {
                  level1Content?.dropdownLinks?.forEach(link => {
                    const dropdownLinkValue = link?.link as SanityLinkInternal
                    if (currentPath === getUrlFromPageData(dropdownLinkValue?._type, dropdownLinkValue?.slug)) {
                      hasActiveDropdownLink = true
                    }
                  })
                }

                if (hasActiveDropdownLink) {
                  linkIsActive = true
                }

                let content = null
                if (level1Content?.type === 'dropdown' && level1Content?.title) {
                  content = (
                    <button
                      onClick={() => {
                        setActiveDropdownIndex(prev => {
                          if (i === prev) return -1
                          return i
                        })
                      }}
                      className={classNames(
                        styles.level1Title,
                        { [styles.isActive]: isActiveDropdown },
                        { [styles.linkIsActive]: linkIsActive },
                      )}
                    >
                      <span className={styles.level1Title__text}>
                        {level1Content?.title}
                        {linkIsActive && <span className={styles.activeBall} />}
                      </span>
                      <span className={styles.level1Title__icon}>
                        <ChevronDown />
                      </span>
                    </button>
                  )
                } else if (level1Content?.type === 'link' && level1Content?.link) {
                  content = (
                    <Link
                      className={classNames(styles.level1Link, { [styles.linkIsActive]: linkIsActive })}
                      link={level1Content?.link}
                      onClick={() => {
                        setActiveDropdownIndex(-1)
                      }}
                      activeClass={styles.activeLink}
                      onFocus={() => {
                        setActiveDropdownIndex(-1)
                      }}
                    >
                      {level1Content?.link?.label}
                      {linkIsActive && <span className={styles.activeBall} />}
                    </Link>
                  )
                }

                let dropdownLinkContent = null
                if (hasDropdown) {
                  dropdownLinkContent = (
                    <>
                      <div
                        className={styles.dropdownContentBg}
                        ref={ref => {
                          if (!dropdownRefs.current[i]) {
                            dropdownRefs.current[i] = { bg: null, list: null }
                          }
                          dropdownRefs.current[i].bg = ref
                        }}
                      />
                      <div
                        className={styles.dropdownContent}
                        ref={ref => {
                          dropdownRefs.current[i].list = ref
                        }}
                      >
                        <ul className={styles.dropdownContent__list}>
                          {level1Content?.dropdownLinks?.map((link, i) => {
                            return (
                              <li
                                key={i}
                                className={styles.dropdownContent__listItem}
                              >
                                <Link
                                  link={link}
                                  className={styles.dropdownContent__link}
                                  onClick={() => {
                                    setActiveDropdownIndex(-1)
                                  }}
                                  activeClass={styles.activeLink}
                                  tabIndexHidden={!isActiveDropdown}
                                />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </>
                  )
                }

                return (
                  <li
                    key={i}
                    className={styles.rightContent__listItem}
                  >
                    {dropdownLinkContent ? (
                      <FocusLock disabled={!isActiveDropdown}>
                        {content}
                        {dropdownLinkContent}
                      </FocusLock>
                    ) : (
                      <>{content}</>
                    )}
                  </li>
                )
              })}
            </ul>
            <span className={styles.loginLine} />
            <Link
              className={styles.loginLink}
              link={navigationData?.loginLink}
              activeClass={styles.activeLink}
            />
          </nav>
        </div>
      </header>
      {isMobile && (
        <>
          <button
            ref={mobileButtonRef}
            className={styles.mobileButton}
            onClick={() => {
              setNavIsOpen(true)
            }}
            aria-label="open mobile menu"
          >
            <span className={classNames(styles.mobileButton__line, styles.line1)} />
            <span className={classNames(styles.mobileButton__line, styles.line2)} />
          </button>
          <FocusLock>
            <NavigationMobileMenu navigationData={navigationData} />
          </FocusLock>
        </>
      )}
    </>
  )
}

Navigation.displayName = 'Navigation'

export default Navigation
