'use client'

import { ScrollProvider } from '@/context/Scroll'
import { useEffect } from 'react'
import styles from './Layout.module.scss'
import useStore from '@/store'
import { deviceInfo } from '@/utils'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Navigation from '@/components/Navigation/Navigation'
import Wipe from '@/components/Wipe/Wipe'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

export const SCROLL_CONTAINER_CLASS = styles.scrollContainer
export const SCROLL_CONTENT_CLASS = styles.scrollContainerInner

const Layout = ({ children }: SanityLayout) => {
  const canInteract = useStore(state => state.canInteract)
  const setFontsLoaded = useStore(state => state.setFontsLoaded)

  // Set high-level body & document attributes
  useEffect(() => {
    // Device Info
    document.body.dataset.browser = deviceInfo.browser.name
    document.body.dataset.device = deviceInfo.device.type
    document.body.dataset.os = deviceInfo.os.name

    // Can interact
    document.body.dataset.enableInteraction = `${canInteract}`
  }, [canInteract])

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsLoaded(true)
    })
  }, [setFontsLoaded])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY as string}>
      <ScrollProvider>
        <Wipe />
        <Navigation />
        <main className={SCROLL_CONTAINER_CLASS}>
          <div className={SCROLL_CONTENT_CLASS}>{children}</div>
        </main>
      </ScrollProvider>
    </GoogleReCaptchaProvider>
  )
}

Layout.displayName = 'Layout'

export default Layout
