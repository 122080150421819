import cmsSettings from '../cmsSettings'
import imageAsset from '../imageAsset'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  ${imageAsset.fragment('image')}
`

export const fragment = (name = 'standaloneMedia') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
