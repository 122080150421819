import cmsSettings from '../cmsSettings'
import person from '../person'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  teamMembers[]-> {${person.fields}}
`

export const fragment = (name = 'teamMembers') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
