import cmsSettings from '../cmsSettings'
import link from '../link'
import imageAsset from '../imageAsset'
import richTextBasic from '../richTextBasic'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  isStacked,
  imageOrientation,
  items[]{
    isHidden,
    lottieIconAnimation,
    eyebrow,
    title,
    ${richTextBasic.fragment('description')},
    ${link.fragment('link')},
    ${imageAsset.fragment('image')},
    imageCaption
  }
`

export const fragment = (name = 'sideBySides') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
