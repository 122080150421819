import { ClientConfig, createClient } from 'next-sanity'
import { page, siteSettings } from '@/data/sanity/fragments'
import { formatSiteSettingsResponse, getSections, mergeSiteSettings } from '@/utils'
import { DOC_TYPES, FOUR_OH_FOUR_SLUG } from '@/data'
import { pageSitemapFields } from '@/data/sanity/fragments/_shared'

let perspective = process.env.SANITY_PREVIEW_TOKEN === undefined ? 'published' : 'previewDrafts'
if (process.env.NODE_ENV === 'development') {
  perspective = 'raw'
}

const SANITY_CONFIG: ClientConfig = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: process.env.SANITY_PREVIEW_TOKEN === undefined ? true : false,
  token: process.env.SANITY_PREVIEW_TOKEN,
  perspective: perspective as 'raw' | 'published' | 'previewDrafts',
}

export const client = createClient(SANITY_CONFIG)

export const getSiteSettings = async ({ isPreview }: { isPreview: boolean }) => {
  /* eslint-disable */
  const siteSettingsData: SanitySiteSettingsResponse = await client.fetch(
    `
      *[(
        _type == "siteSettingsMetadata" ||
        _type == "navigation" ||
        _type == "footer"
        )] {
        ${siteSettings.fields}
      }
  `,
    { isPreview: Boolean(isPreview) },
  )

  /* eslint-enable */

  if (!siteSettingsData?.length) return null

  const formatted = formatSiteSettingsResponse(siteSettingsData)

  return formatted as SanitySiteSettingsFormatted
}

export const getPage = async (slug: string, type = 'page', isPreview: boolean) => {
  if (slug === 'favicon.ico') slug = FOUR_OH_FOUR_SLUG

  const query = `
    *[_type == "${type}" && slug.current == "${slug}"] {
      ${page.getFields()}
    }
  `
  const response = (await client.fetch(query, { isPreview: Boolean(isPreview) }))[0]
  if (!response || !siteSettings) return null
  const merged = mergeSiteSettings(response, response.globalMetaData) as SanityPage
  const newSections = getSections(merged)
  merged.sections = newSections
  return merged as SanityPage
}

export const getAllPages = async (type = DOC_TYPES.PAGE, isPreview?: boolean, isSitemapGeneration?: boolean) => {
  const docTypes = typeof DOC_TYPES === 'object' && Object.keys(DOC_TYPES).length > 0 ? Object.values(DOC_TYPES) : []
  const docTypesOrCondition = docTypes.map(type => `_type == "${type}"`).join(' || ')
  const sitemapQuery = ` *[${docTypesOrCondition}] {
      ${pageSitemapFields}
    }
  `
  const pagesQuery = `*[_type == "${type}"] {
      ${page.getFields()}
    }
  `

  const pages = await client.fetch(isSitemapGeneration ? sitemapQuery : pagesQuery, { isPreview: Boolean(isPreview) })

  if (!pages.length) return null

  return pages as SanityPage[]
}

export const getAllBlogCategories = async (isPreview?: boolean) => {
  const documents = await client.fetch(
    `
    *[_type == "blogCategory"] {
      title,
      _createdAt
    }
  `,
    { isPreview: Boolean(isPreview) },
  )

  if (!documents) return null

  return documents as { title: string; _createdAt: string }[]
}
