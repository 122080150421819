import { richTextBasic } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  eyebrow,
  subtitle,
  ${richTextBasic.fragment('content')}
`

export const fragment = (name = 'richTextBlock') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
