import link from './link'

export const fields = `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {
      ${link.fields}
    },
  }
`

export const fragment = (name = 'richTextBasic') => `${name}[]{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
